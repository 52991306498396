<template>
  <div>
    <div class="card plan-box overflow-hidden">
      <div class="bg-success p-3">
        <div class="d-flex">
          <div class="flex-grow-1">
            <h5 class="text-white">Módulo Inventario</h5>
          </div>
          <div class="flex-shrink-0 ms-3">
            <i class="bx bx-hive h3 mb-0 text-white"></i>
          </div>
        </div>
      </div>
      <div class="card-body p-4">
        <div v-show="estado_modulo">
          <h2 class="text-center">
            <strong>Plan {{modulo_inventario.nombre_plan}}</strong>
          </h2>
          <br>
          <div class="table-responsive">
            <table class="table">
              <tbody class="text-center">
                <tr>
                  <td style="width:50%;">
                    Articulos
                  </td>
                  <td>
                    {{modulo_inventario.cantidad_articulos}} de
                    {{plan_inventario.max_productos != -1 ? plan_inventario.max_productos : 'Ilimitado'}}
                  </td>
                </tr>
                <tr>
                  <td>
                    Almacenamiento
                  </td>
                  <td>
                    {{modulo_inventario.almacenamiento_usado}}MB de
                    {{plan_inventario.max_almacenamiento != -1 ? plan_inventario.max_almacenamiento : 'Ilimitado'}}MB
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br><br><br><br><br>
          <h3 class="text-center">
            <strong>Precio {{formatoMoneda(plan_inventario.precio_mensual)}} USD</strong>
          </h3>
        </div>
        <div v-show="!estado_modulo" class="text-center p-5 m-2">
          <span>
            <i class="bx bxs-info-circle text-warning" style="font-size: 10em;"></i>
          </span>
          <br><br>
          <span class="h3">
            <strong>Módulo desactivado</strong>
          </span>
        </div>
        <div class="text-center">
          <button class="btn bg-gradient btn-lg" 
            :class="{
              'btn-success': estado_modulo,
              'btn-danger': !estado_modulo
            }"
            @click="actDescEstModulo()"
          >
            <strong>
              MODULO {{ estado_modulo ? 'ACTIVO' : 'INACTIVO' }}
            </strong>
          </button>
        </div>
        <div class="text-center" v-show="estado_modulo">
          <button
            class="btn btn-link text-primary"
            @click="caracteristicasInventario(plan_inventario.id)"
          >
            Ver todas las caracteristicas
          </button>
        </div>
      </div>
      <div class="card-footer bg-transparent border-top text-muted no-borders" style="background-color: #fff;">
        <router-link :to="{ name: 'sistemaModuloInventario' }">
          <div class="p-2 m-0 rounded text-center bg-soft-dark">
            <span class="h4" style="color: #337AB7;">
              <strong>Planes Inventario disponibles</strong>
            </span>
          </div>
        </router-link>
      </div>
    </div>
    <MdlCaracteristicasInventario ref="mdlCaracteristicasInventario"></MdlCaracteristicasInventario>
  </div>
</template>

<script>
import MdlCaracteristicasInventario from "./inventario/MdlCaracteristicasInventario.vue"
import CuentaSrv from "@/services/CuentaSrv.js"
import accounting from 'accounting/accounting.js'
export default {
  name: 'WModuloInventario',
  props: [ 'modulo_inventario', 'modulos_activos' ],
  components: {
    MdlCaracteristicasInventario
  },
  data: function() {
    return {
      estado_modulo: false,

      plan_inventario: {
        id: null,
        descripcion: "",
        max_almacenamiento: 0,
        max_productos: 0,
        nombre: "",
        precio_anual: 0,
        precio_mensual: 0
      }
    }
  },
  watch: {
    'modulo_inventario.id_plan':function(newVal, oldVal) {
      var self = this
      if(self.modulo_inventario.id_plan == null) return

      self.cargarPlan()
    }
  },
  created: function() {
    var self = this

    self.cargarEstado()
  },
  methods: {
    actDescEstModulo: function() {
      var self = this

      if(self.estado_modulo != 1) {
        CuentaSrv.activarModulo('inventario').then(response => {
          iu.msg.success('Modulo inventario activado correctamente')
          self.cargarEstado()
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo activar el modulo inventario'
          }
          iu.msg.error(mensaje)
          console.log(error)
        }).finally(() => {
          iu.bus.emit('actualizarCuenta')
        })
      } else {
        CuentaSrv.desactivarModulo('inventario').then(response => {
          iu.msg.success('Modulo inventario desactivado correctamente')
          self.cargarEstado()
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo desactivar el modulo inventario'
          }
          iu.msg.error(mensaje)
          console.log(error)
        }).finally(() => {
          iu.bus.emit('actualizarCuenta')
        })
      }
    },

    cargarEstado: function() {
      var self = this

      CuentaSrv.estadoModulo('inventario').then(response => {
        self.estado_modulo = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar cuenta'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarPlan: function (){
      var self = this,
          idPlan = self.modulo_inventario.id_plan

      CuentaSrv.moduloPlanJSON('inventario', idPlan).then(response => {
        self.plan_inventario = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar cuenta'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    caracteristicasInventario: function(idInventario) {
      this.$refs.mdlCaracteristicasInventario.mostrar(idInventario, "tablero")
    },
    formatoMoneda: function (numero) {
      return accounting.formatMoney(numero)
    },
  }
}
</script>

<style scoped>

</style>