<template>
  <Layout tituloPagina="Sistema | Cuenta">
    <div class="row">
      <div class="col-lg-4">
        <WSuscripcion :empresa_livaur="cuenta.empresa"></WSuscripcion>
      </div>
      <div class="col-lg-4">
        <w-tipo-suscripcion :empresa_livaur="cuenta.empresa"></w-tipo-suscripcion>
      </div>
      <div class="col-lg-4">
        <w-saldo></w-saldo>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-lg-3">
        <WModuloIsp :modulo_isp="cuenta.modulo_isp" :modulos_activos="modulos_activos"></WModuloIsp>
      </div>
      <div class="col-lg-3">
        <w-modulo-hotspot :modulo_hotspot="cuenta.modulo_hotspot" :modulos_activos="modulos_activos"></w-modulo-hotspot>
      </div>
      <div class="col-lg-3">
        <w-modulo-inventario :modulo_inventario="cuenta.modulo_inventario" :modulos_activos="modulos_activos"></w-modulo-inventario>
      </div>
      <div class="col-lg-3">
        <w-vpn :modulo_vpn="cuenta.modulo_vpn" :modulos_activos="modulos_activos"></w-vpn>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-lg-3">
        <w-Soporte></w-Soporte>
      </div>
    </div> -->
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main"

import WModuloIsp from "./WModuloIsp.vue"
import WModuloHotspot from "./WModuloHotspot.vue"
import WModuloInventario from "./WModuloInventario.vue"
import WTipoSuscripcion from "./WTipoSuscripcion.vue"
import WSaldo from "./WSaldo.vue"
import WSuscripcion from './WSuscripcion.vue'
import WVpn from "./WVpn.vue"
// import WSoporte from "./WSoporte.vue"
import CuentaSrv from "@/services/CuentaSrv.js"
export default {
  name: 'Tablero',
  components: {
    Layout,
    WModuloIsp,
    WModuloHotspot,
    WModuloInventario,
    WTipoSuscripcion,
    WSaldo,
    WVpn,
    WSuscripcion,
    // WSoporte
  },
  data() {
    return {
      cuenta: {
        cuenta_master: {
          id: null,
          nombre: "",
          apellido_materno: "",
          apellido_paterno: "",
          telefono: "",
          correo: "",
          creditos_sgcm: 0,
          ultimo_inicio_sesion: ""
        },
        empresa: {
          id: null,
          tipo_suscripcion: 1,
          id_cliente: null,
          id_empresa: null,
          id_plan_hotspot: null,
          id_plan_inventario: null,
          id_plan_isp: null,
          id_plan_vpn: null,
          modulo_hotspot: 0,
          modulo_inventario: 0,
          modulo_isp: 0,
          modulo_vpn: 0
        },
        modulo_hotspot : {
          activo: 0,
          cantidad_fichas: 0,
          cantidad_hotspots: 0,
          id_plan: null,
          nombre_plan: "",
          precio_anual: 0,
          precio_mensual: 0
        },
        modulo_inventario: {
          activo: 0,
          almacenamiento_usado: 0,
          cantidad_articulos: 0,
          id_plan: null,
          nombre_plan: "",
          precio_anual: 0,
          precio_mensual: 0
        },
        modulo_isp:{
          activo: 0,
          cantidad_clientes: 0,
          cantidad_contratos: 0,
          cantidad_cpes: 0,
          cantidad_routers: 0,
          id_plan: null,
          nombre_plan: "",
          precio_anual: 0,
          precio_mensual: 0
        },
        modulo_vpn: {
          activo: 0,
          cantidad_credenciales: 0,
          id_plan_vpn: null,
          nombre_plan: "",
          precio_anual: 0,
          precio_mensual: 0
        }
      }
    }
  },

  created: function() {
    var self = this

    self.cargarCuenta()

    iu.bus.on('actualizarCuenta', function() {
      self.cargarCuenta()
    })
  },
  computed: {
    modulos_activos: function() {
      let self = this, activos = []

      if(self.cuenta.modulo_isp.activo) activos.push('isp')
      if(self.cuenta.modulo_hotspot.activo) activos.push('hotspot')
      if(self.cuenta.modulo_inventario.activo) activos.push('inventario')
      if(self.cuenta.modulo_vpn.activo) activos.push('vpn')

      return activos
    }
  },

  methods: {
    cargarCuenta: function() {
      var self = this

      CuentaSrv.cuentaJSON().then(response => {
        self.cuenta = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar cuenta'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  },
}
</script>

<style>
</style>