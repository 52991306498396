<template>
  <div>
    <div class="card plan-box overflow-hidden">
      <div class="p-3" :class="hoy <= suscripcion.fin ? 'bg-info': 'bg-danger'">
        <div class="d-flex">
          <div class="flex-grow-1">
            <h5 class="text-white">Suscripción actual</h5>
          </div>
          <div class="flex-shrink-0 ms-3">
            <i class="bx bx-file h3 mb-0 text-white"></i>
          </div>
        </div>
      </div>
      <div class="card-body p-4">
        <div class="py-2 text-left">
          <h5 class="mb-0">
            Periodo de la suscripción {{suscripcion.tipo_suscripcion == 1? 'MENSUAL': 'ANUAL'}}:
            <br>
            <span class="font-size-14 text-muted">
              Del {{formatoFecha(suscripcion.inicio)}} al {{formatoFecha(suscripcion.fin)}}
            </span>
          </h5>
        </div>
        <div class="plan-features mt-4">
          <div class="table-responsive">
            <table class="table table-nowrap">
              <thead>
                <tr>
                  <th>Módulo</th>
                  <th>Estado</th>
                  <th>Plan contratado</th>
                  <th>Precio</th>
                </tr>
              </thead>
              <tbody>
                <tr v-show="suscripcion.id_plan_isp != null">
                  <td><strong>ISP</strong></td>
                  <td>
                    <span class="badge bg-gradient font-size-12" :class="{'bg-success': suscripcion.modulo_isp, 'bg-danger': !suscripcion.modulo_isp}">
                      {{suscripcion.modulo_isp ? 'Activo': 'Inactivo'}}
                    </span>
                  </td>
                  <td>{{suscripcion.nombre_plan_isp}}</td>
                  <td class="text-right">{{formatoMoneda(suscripcion.total_plan_isp)}} USD </td>
                </tr>
                <tr v-show="suscripcion.id_plan_hotspot != null">
                  <td><strong>HOTSPOT</strong></td>
                  <td>
                    <span class="badge bg-gradient font-size-12" :class="{'bg-success': suscripcion.modulo_hotspot, 'bg-danger': !suscripcion.modulo_hotspot}">
                      {{suscripcion.modulo_hotspot? 'Activo': 'Inactivo'}}
                    </span>
                  </td>
                  <td>{{suscripcion.nombre_plan_hotspot}}</td>
                  <td class="text-right">{{formatoMoneda(suscripcion.total_plan_hotspot)}} USD </td>
                </tr>
                <tr v-show="suscripcion.id_plan_inventario != null">
                  <td><strong>INVENTARIO</strong></td>
                  <td>
                    <span class="badge bg-gradient font-size-12" :class="{'bg-success': suscripcion.modulo_inventario, 'bg-danger': !suscripcion.modulo_inventario}">
                      {{suscripcion.modulo_inventario? 'Activo': 'Inactivo'}}
                    </span>
                  </td>
                  <td>{{suscripcion.nombre_plan_inventario}}</td>
                  <td class="text-right">{{formatoMoneda(suscripcion.total_plan_inventario)}} USD </td>
                </tr>
                <tr v-show="suscripcion.id_plan_vpn != null">
                  <td><strong>VPN</strong></td>
                  <td>
                    <span class="badge bg-gradient font-size-12" :class="{'bg-success': suscripcion.modulo_vpn, 'bg-danger': !suscripcion.modulo_vpn}">
                      {{suscripcion.modulo_vpn? 'Activo': 'Inactivo'}}
                    </span>
                  </td>
                  <td>{{suscripcion.nombre_plan_vpn}}</td>
                  <td class="text-right">{{formatoMoneda(suscripcion.total_plan_vpn)}} USD </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td class="text-right"><strong>TOTAL</strong></td>
                  <td class="text-right">{{formatoMoneda(suscripcion.total)}} USD </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CuentaSrv from "@/services/CuentaSrv.js"
// import SistemaSrv from '@/services/SistemaSrv.js'
import accounting from 'accounting/accounting.js'
import moment from 'moment'
export default {
  name: 'WSuscripcion',
  data() {
    return {
      hoy: null,
      suscripcion: {
        id_plan_isp: null,
        id_plan_hotspot: null,
        id_plan_inventario: null,
        id_plan_vpn: null,
        modulo_isp: false,
        modulo_hotspot: false,
        modulo_inventario: false,
        modulo_vpn: false,
        nombre_plan_isp: null,
        nombre_plan_hotspot: null,
        nombre_plan_inventario: null,
        nombre_plan_vpn: null,
        total_plan_isp: 0,
        total_plan_hotspot: 0,
        total_plan_inventario: 0,
        total_plan_vpn: 0,
        total: 0,
        tipo_suscripcion: 1,
        inicio: null,
        fin: null,
        estado: null     // 0 = Recién registrada, 1 = En uso, 2 = Finalizada, 3 = Cancelada
      }
    }
  },
  created: function() {
    var self = this

    self.hoy = moment(new Date()).format('YYYY-MM-DD')

    self.refrescarSuscripcion()
    iu.bus.on('actualizarCuenta', function() {
      self.refrescarSuscripcion()
    })
  },
  methods: {
    formatoMoneda: function (numero) {
      return accounting.formatMoney(numero)
    },

    formatoFecha(mt){
      return moment(mt, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },
    refrescarSuscripcion() {
      var self = this

      CuentaSrv.suscripcionJSON().then(response => {
        let suscripcion = response.data
        Object.assign(self.suscripcion, suscripcion)
      })
    }
  }
}
</script>

<style scoped>

</style>