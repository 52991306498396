<template>
  <div class="modal fade" ref="mdlCaracteristasPlan" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content" style="border:none">
        <div class="modal-header bg-success">
          <h4 class="modal-title text-white">
            Caracteristicas del {{plan.nombre}} {{modo == "tablero" ? "actual" : ""}}
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table table-hover">
                  <tbody>
                    <tr>
                      <th style="width:50%">Descripción</th>
                      <td style="width:50%">{{plan.descripcion}}</td>
                    </tr>
                    <tr>
                      <th>Gestión de clientes</th>
                      <td>{{plan.max_clientes != -1 ? 'hasta '+plan.max_clientes : 'Ilimitado'}}</td>
                    </tr>
                    <tr>
                      <th>Gestión de contratos</th>
                      <td>{{plan.max_contratos != -1 ? 'hasta '+plan.max_contratos : 'Ilimitado'}}</td>
                    </tr>
                    <tr>
                      <th>Gestión de CPEs</th>
                      <td>{{plan.max_cpes != -1 ? 'hasta '+plan.max_cpes : 'Ilimitado'}}</td>
                    </tr>
                    <tr>
                      <th>Gestión de routers de administración</th>
                      <td>{{plan.max_routers != -1 ? 'hasta '+plan.max_routers : 'Ilimitado'}}</td>
                    </tr>
                    <tr>
                      <th>Notificación de routers de administración caídos</th>
                      <td>{{plan.notificacion_routers_caidos ? "SI": "NO"}}</td>
                    </tr>
                    <tr>
                      <th>Generación de facturas</th>
                      <td>{{plan.generacion_facturas ? "SI": "NO"}}</td>
                    </tr>
                    <tr>
                      <th>Cortes de servicios</th>
                      <td>{{plan.cortes_servicios ? "SI": "NO"}}</td>
                    </tr>
                    <tr>
                      <th>Mensaje de servicio suspendido</th>
                      <td>{{plan.mensaje_servicio_suspendido ? "SI": "NO"}}</td>
                    </tr>
                    <tr>
                      <th>Reportes diarios</th>
                      <td>{{plan.reportes_diarios ? "SI": "NO"}}</td>
                    </tr>
                    <tr>
                      <th>Notificación por correo electrónico</th>
                      <td>{{plan.notificacion_por_email ? "SI": "NO"}}</td>
                    </tr>
                    <tr>
                      <th>Precio mensual</th>
                      <td>{{formatoMoneda(plan.precio_mensual)}} USD</td>
                    </tr>
                    <tr>
                      <th>Precio anual</th>
                      <td>
                        {{formatoMoneda(plan.precio_anual)}} USD <br>
                        <strong>{{costoAnualPocentaje(plan.precio_mensual, plan.precio_anual)}}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-2 offset-md-2 text-center">
              <div 
                class="p-2 m-0 rounded text-center bg-soft-dark"
                data-bs-dismiss="modal"
                style="cursor: pointer;"
              >
                <span class="h4">
                  <strong>Cerrar</strong>
                </span>
              </div>
            </div>
            <div class="col-md-5 offset-md-2 text-center">
              <router-link :to="{ name: 'sistemaPlanesIsp' }" data-bs-dismiss="modal" v-show="modo=='tablero'">
                <div class="p-2 m-0 rounded text-center bg-soft-dark">
                  <span class="h4">
                    <strong>Planes ISP disponibles</strong>
                  </span>
                </div>
              </router-link>
              <router-link :to="{ name: 'actualizarPlanIsp', params:{ id_plan: plan.id } }" data-bs-dismiss="modal" v-if="modo=='planes'">
                <div class="p-2 m-0 rounded text-center bg-soft-dark">
                  <span class="h4">
                    <strong>{{estado_modulo ? 'Cambiar' : 'Activar'}} plan ISP</strong>
                  </span>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CuentaSrv from "@/services/CuentaSrv.js"
import Modal from 'bootstrap/js/dist/modal'
import accounting from 'accounting/accounting.js'
export default {
  name: 'MdlCaracteristicasIsp',

  data() {
    return {
      modo: "tablero",
      estado_modulo: false,
      id_plan: null,
      plan: {
        id:null,
        nombre: "",
        descripcion: "",
        max_clientes: 0,
        max_contratos: 0,
        max_cpes: 0,
        max_routers: 0,
        notificacion_routers_caidos: false,
        generacion_facturas: false,
        cortes_servicios: false,
        mensaje_servicio_suspendido: false,
        reportes_diarios: false,
        notificacion_por_email: false,
        precio_mensual: 0,
        precio_anual: 0,
      }
    }
  },

  methods: {
    cargarPlan: function(){
      var self = this

      CuentaSrv.moduloPlanJSON('isp', self.id_plan).then(response => {
        self.plan = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar cuenta'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    costoAnualPocentaje: function(precio_mensual, precio_anual){
      if(precio_anual < 0) return ''

      let total = precio_mensual * 12
      let porcentaje = ((total - precio_anual) * 100) / total

      if(porcentaje < 0) return ''

      return `costo anual (incluye ${parseInt(porcentaje)}% de descuento)`
    },
    formatoMoneda: function (numero) {
      return accounting.formatMoney(numero)
    },
    mostrar(idPlan, modo = "tablero", estado = false) {
      var self = this
      self.id_plan = idPlan
      self.modo = modo
      self.estado_modulo = estado
      
      self.cargarPlan()

      var modal = new Modal(this.$refs.mdlCaracteristasPlan)
      modal.show()
    }
  },
}
</script>

<style scoped>

</style>