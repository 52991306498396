<template>
    <div class="modal fade" ref="mdlCaracteristasHotspot" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content" style="border:none">
            <div class="modal-header bg-success">
              <h4 class="modal-title text-white">
                Caracteristicas del {{plan.nombre}} {{modo == "tablero" ? "actual" : ""}}
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th style="width: 50%;">Descripción</th>
                          <td>{{plan.descripcion}}</td>
                        </tr>
                        <tr>
                          <th>Notificación de routers de administración caídos</th>
                          <td>{{plan.notificacion_routers_caidos ? 'SI' : 'NO'}}</td>
                        </tr>
                        <tr>
                          <th>Gestión de routers para hotspots</th>
                          <td>{{plan.max_routers != -1 ? 'hasta '+plan.max_routers : 'Ilimitado'}}</td>
                        </tr>
                        <tr>
                          <th>Diseño de portal cautivo</th>
                          <td>{{plan.layout_portal_cautivo ? 'SI' : 'NO'}}</td>
                        </tr>
                        <tr>
                          <th>Hotspot</th>
                          <td>{{plan.max_hotspots != -1 ? 'hasta '+plan.max_hotspots : 'Ilimitado'}}</td>
                        </tr>
                        <tr>
                          <th>Fichas</th>
                          <td>{{plan.max_fichas != -1 ? 'hasta '+plan.max_fichas : 'Ilimitado'}}</td>
                        </tr>
                        <tr>
                          <th>Impresión de fichas</th>
                          <td>SI</td>
                        </tr>
                        <tr>
                          <th>Diseño de fichas</th>
                          <td>{{plan.layout_fichas ? 'SI' : 'NO'}}</td>
                        </tr>
                        <tr>
                          <th>Generación de fichas desde cualquier lugar</th>
                          <td>SI</td>
                        </tr>
                        <tr>
                          <th>Eliminación de fichas vencidas en los routers.</th>
                          <td>SI</td>
                        </tr>
                        <tr>
                          <th>Precio mensual</th>
                          <td>{{formatoMoneda(plan.precio_mensual)}} USD</td>
                        </tr>
                        <tr>
                          <th>Precio anual</th>
                          <td>
                            {{formatoMoneda(plan.precio_anual)}} USD <br>
                            <strong>{{costoAnualPocentaje(plan.precio_mensual, plan.precio_anual)}}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-2 offset-md-2 text-center">
                  <div 
                    class="p-2 m-0 rounded text-center bg-soft-dark"
                    data-bs-dismiss="modal"
                    style="cursor: pointer;"
                  >
                    <span class="h4">
                      <strong>Cerrar</strong>
                    </span>
                  </div>
                </div>
                <div class="col-md-5 offset-md-2 text-center">
                  <router-link :to="{ name: 'sistemaModuloHotspot' }" data-bs-dismiss="modal" v-show="modo=='tablero'">
                    <div class="p-2 m-0 rounded text-center bg-soft-dark">
                      <span class="h4">
                        <strong>Planes Hotspot disponibles</strong>
                      </span>
                    </div>
                  </router-link>
                  <router-link :to="{ name: 'actualizarPlanHotspot', params:{ id_plan: plan.id } }" data-bs-dismiss="modal" v-if="modo=='planes'">
                    <div class="p-2 m-0 rounded text-center bg-soft-dark">
                      <span class="h4">
                        <strong>{{estado_modulo ? 'Cambiar' : 'Activar'}} plan Hotspot</strong>
                      </span>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import CuentaSrv from "@/services/CuentaSrv.js"
import Modal from 'bootstrap/js/dist/modal'
import accounting from 'accounting/accounting.js'
export default {
  name: 'MdlCaracteristicasHotspot',

  data() {
    return {
      modo: "tablero",
      estado_modulo: false,
      id_plan: null,
      plan: {
        id: null,
        descripcion: "",
        layout_fichas: 0,
        layout_portal_cautivo: 0, 
        max_fichas: 0,
        max_hotspots: 0,
        max_routers: 0,
        nombre: "", 
        notificacion_routers_caidos: 0, 
        precio_anual: 0,
        precio_mensual: 0
      }
    }
  },

  methods: {
    cargarPlan: function(){
      var self = this

      CuentaSrv.moduloPlanJSON('hotspot', self.id_plan).then(response => {
        self.plan = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el plan'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    costoAnualPocentaje: function(precio_mensual, precio_anual){
      if(precio_anual < 0) return ''

      let total = precio_mensual * 12
      let porcentaje = ((total - precio_anual) * 100) / total

      if(porcentaje < 0) return ''

      return `costo anual (incluye ${parseInt(porcentaje)}% de descuento)`
    },

    formatoMoneda: function (numero) {
      return accounting.formatMoney(numero)
    },

    mostrar(idPlan, modo = "tablero", estado = false) {
      var self = this
      self.id_plan = idPlan
      self.modo = modo
      self.estado_modulo = estado
      
      self.cargarPlan()

      var modal = new Modal(this.$refs.mdlCaracteristasHotspot)
      modal.show()
    }
  }
}
</script>

<style scoped>

</style>