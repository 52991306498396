<template>
  <div>
    <div class="card plan-box overflow-hidden">
      <div class="bg-success p-3">
        <div class="d-flex">
          <div class="flex-grow-1">
            <h5 class="text-white">Módulo VPN</h5>
          </div>
          <div class="flex-shrink-0 ms-3">
            <i class="bx bx-shield-quarter h3 mb-0 text-white"></i>
          </div>
        </div>
      </div>
      <div class="card-body p-4">
        <div v-show="estado_modulo">
          <h2 class="text-center">
            <strong>Plan {{modulo_vpn.nombre_plan}}</strong>
          </h2>
          <br>
          <div class="table-responsive">
            <table class="table">
              <tbody class="text-center">
                <tr>
                  <td style="width:50%;">Credenciales</td>
                  <td>{{modulo_vpn.cantidad_credenciales}} de {{plan_vpn.max_credenciales != -1 ? plan_vpn.max_credenciales : 'Ilimitado'}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <br><br><br><br><br><br><br>
          <h3 class="text-center">
            <strong>Precio {{formatoMoneda(plan_vpn.precio_mensual)}} USD</strong>
          </h3>
        </div>
        <div v-show="!estado_modulo" class="text-center p-5 m-2">
          <span>
            <i class="bx bxs-info-circle text-warning" style="font-size: 10em;"></i>
          </span>
          <br><br>
          <span class="h3">
            <strong>Módulo desactivado</strong>
          </span>
        </div>
        <div class="text-center">
          <button class="btn bg-gradient btn-lg" 
            :class="{
              'btn-success': estado_modulo,
              'btn-danger': !estado_modulo
            }"
            @click="actDescEstModulo()"
          >
            <strong>
              MODULO {{ estado_modulo ? 'ACTIVO' : 'INACTIVO' }}
            </strong>
          </button>
        </div>
        <div class="text-center" v-show="estado_modulo">
          <button
            class="btn btn-link text-primary"
            @click="caracteristicasVpn(plan_vpn.id)"
          >
            Ver todas las caracteristicas
          </button>
        </div>
      </div>
      <div class="card-footer bg-transparent border-top text-muted no-borders" style="background-color: #fff;">
        <router-link :to="{ name: 'sistemaModuloVpn' }">
          <div class="p-2 m-0 rounded text-center bg-soft-dark">
            <span class="h4" style="color: #337AB7;">
              <strong>Planes VPN disponibles</strong>
            </span>
          </div>
        </router-link>
      </div>
    </div>
    <mdlCaracteristicasVpn ref="mdlCaracteristicasVpn"></mdlCaracteristicasVpn>
  </div>
</template>

<script>
import MdlCaracteristicasVpn from "./vpn/MdlCaracteristicasVpn"
import CuentaSrv from "@/services/CuentaSrv.js"
import accounting from 'accounting/accounting.js'
export default {
  name: 'WVpn',
  props: [ 'modulo_vpn', 'modulos_activos' ],
  components: {
    MdlCaracteristicasVpn
  },
  data() {
    return {
      estado_modulo: false,

      plan_vpn: {
        id:null,
        nombre: "",
        descripcion: "",
        max_credenciales: 0,
        precio_mensual: 0,
        precio_anual: 0,
      }
    }
  },
  watch: {
    'modulo_vpn.id_plan_vpn':function(newVal, oldVal) {
      var self = this
      if(self.modulo_vpn.id_plan_vpn == null) return

      self.cargarPlan()
    }
  },
  created: function() {
    var self = this

    self.cargarEstado()
  },
  methods: {
    actDescEstModulo: function() {
      var self = this

      if(self.estado_modulo != 1) {
        CuentaSrv.activarModulo('vpn').then(response => {
          iu.msg.success('Modulo VPN activado correctamente')
          self.cargarEstado()
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo activar el modulo VPN'
          }
          iu.msg.error(mensaje)
          console.log(error)
        }).finally(() => {
          iu.bus.$emit('actualizarCuenta')
        })
      } else {
        CuentaSrv.desactivarModulo('vpn').then(response => {
          iu.msg.success('Modulo VPN desactivado correctamente')
          self.cargarEstado()
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo desactivar el modulo VPN'
          }
          iu.msg.error(mensaje)
          console.log(error)
        }).finally(() => {
          this.$emit('actualizarCuenta')
        })
      }
    },
    caracteristicasVpn: function(idVpn) {
      this.$refs.mdlCaracteristicasVpn.mostrar(idVpn, "tablero")
    },
    cargarEstado: function() {
      var self = this

      CuentaSrv.estadoModulo('vpn').then(response => {
        self.estado_modulo = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar cuenta'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    cargarPlan: function (){
      var self = this,
          idPlan = self.modulo_vpn.id_plan_vpn

      CuentaSrv.moduloPlanJSON('vpn', idPlan).then(response => {
        self.plan_vpn = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar cuenta'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    formatoMoneda: function (numero) {
      return accounting.formatMoney(numero)
    },
  }
}
</script>

<style scoped>

</style>