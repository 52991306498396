<template>
  <div class="modal fade" ref="mdlCaracteristicasVpn" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content" style="border:none">
        <div class="modal-header bg-success">
          <h4 class="modal-title text-white">
            Caracteristicas del {{plan.nombre}} {{modo == "tablero" ? "actual" : ""}}
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table">
                  <tbody>
                    <tr>
                      <th style="width:50%">Descripción</th>
                      <td>{{plan.descripcion}}</td>
                    </tr>
                    <tr>
                      <th>
                        Credencial de usuario para VPN
                      </th>
                      <td>
                        {{plan.max_credenciales}} usuarios
                      </td>
                    </tr>
                    <tr>
                      <th>Precio mensual</th>
                      <td>{{formatoMoneda(plan.precio_mensual)}} USD</td>
                    </tr>
                    <tr>
                      <th>Precio anual</th>
                      <td>
                        {{formatoMoneda(plan.precio_anual)}} USD <br>
                        <strong>{{costoAnualPocentaje(plan.precio_mensual, plan.precio_anual)}}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2 offset-md-2 text-center">
              <div 
                class="p-2 m-0 rounded text-center bg-soft-dark"
                data-bs-dismiss="modal"
                style="cursor: pointer;"
              >
                <span class="h4">
                  <strong>Cerrar</strong>
                </span>
              </div>
            </div>
            <div class="col-md-5 offset-md-2 text-center">
              <router-link :to="{ name: 'sistemaModuloVpn' }" data-bs-dismiss="modal" v-show="modo=='tablero'">
                <div class="p-2 m-0 rounded text-center bg-soft-dark">
                  <span class="h4">
                    <strong>Planes VPN disponibles</strong>
                  </span>
                </div>
              </router-link>
              <router-link :to="{ name: 'actualizarPlanVpn', params:{ id_plan: plan.id } }" data-bs-dismiss="modal" v-if="modo=='planes'">
                <div class="p-2 m-0 rounded text-center bg-soft-dark">
                  <span class="h4">
                    <strong>{{estado_modulo ? 'Cambiar' : 'Activar'}} plan VPN</strong>
                  </span>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal'
import CuentaSrv from "@/services/CuentaSrv.js"
import accounting from 'accounting/accounting.js'
export default {
  name: 'MdlCaracteristicasVpn',

  data() {
    return {
      modo: "tablero",
      estado_modulo: false,
      id_plan: null,
      plan: {
        id: null,
        descripcion: "",
        max_usuarios: 0,
        nombre: "",
        precio_anual: 0,
        precio_mensual: 0
      }
    }
  },

  mounted() {
        
  },

  methods: {
    cargarPlan: function(){
      var self = this

      CuentaSrv.moduloPlanJSON('vpn', self.id_plan).then(response => {
        self.plan = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el plan'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    costoAnualPocentaje: function(precio_mensual, precio_anual){
      if(precio_anual < 0) return ''

      let total = precio_mensual * 12
      let porcentaje = ((total - precio_anual) * 100) / total

      if(porcentaje < 0) return ''

      return `costo anual (incluye ${parseInt(porcentaje)}% de descuento)`
    },


    formatoMoneda: function (numero) {
      return accounting.formatMoney(numero)
    },

    mostrar(idPlan, modo = "tablero", estado = false) {
      var self = this
      self.id_plan = idPlan
      self.modo = modo
      self.estado_modulo = estado
      self.cargarPlan()
      var modal = new Modal(this.$refs.mdlCaracteristicasVpn)
      modal.show()
    }
  },
}
</script>

<style scoped>

</style>