<template>
  <div>
    <div class="card plan-box overflow-hidden">
      <div class="bg-success p-3">
        <div class="d-flex">
          <div class="flex-grow-1">
            <h5 class="text-white">Módulo ISP</h5>
          </div>
          <div class="flex-shrink-0 ms-3">
            <i class="bx bx-check-square h3 mb-0 text-white"></i>
          </div>
        </div>
      </div>
      <div class="card-body p-4">
        <div v-show="estado_modulo">
          <h2 class="text-center">
            <strong>Plan {{modulo_isp.nombre_plan}}</strong>
          </h2>
          <br>
          <div class="table-responsive">
            <table class="table">
              <tbody class="text-center">
                <tr>
                  <td style="width:50%;"><strong>Clientes</strong></td>
                  <td>{{modulo_isp.cantidad_clientes}} de {{plan_isp.max_clientes != -1 ? plan_isp.max_clientes : 'Ilimitado'}}</td>
                </tr>
                <tr>
                  <td><strong>Contratos</strong></td>
                  <td>{{modulo_isp.cantidad_contratos}} de {{plan_isp.max_contratos != -1 ? plan_isp.max_contratos : 'Ilimitado'}}</td>
                </tr>
                <tr>
                  <td><strong>CPEs</strong></td>
                  <td>{{modulo_isp.cantidad_cpes}} de {{plan_isp.max_cpes != -1 ? plan_isp.max_cpes : 'Ilimitado'}}</td>
                </tr>
                <tr>
                  <td><strong>Routers de administración</strong></td>
                  <td>{{modulo_isp.cantidad_routers}} de {{plan_isp.max_routers != -1 ? plan_isp.max_routers : 'Ilimitado'}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3 class="text-center">
            <strong>Precio {{formatoMoneda(plan_isp.precio_mensual)}} USD</strong>
          </h3>
        </div>
        <div v-show="!estado_modulo" class="text-center p-5 m-2">
          <span>
            <i class="bx bxs-info-circle text-warning" style="font-size: 10em;"></i>
          </span>
          <br><br>
          <span class="h3">
            <strong>Módulo desactivado</strong>
          </span>
        </div>
        <div class="text-center">
          <button class="btn bg-gradient btn-lg" 
            :class="{
              'btn-success': estado_modulo,
              'btn-danger': !estado_modulo
            }"
            @click="actDescEstModulo()"
          >
            <strong>
              MODULO {{ estado_modulo ? 'ACTIVO' : 'INACTIVO' }}
            </strong>
          </button>
        </div>
        <div class="text-center" v-show="estado_modulo">
          <button
            class="btn btn-link text-primary"
            @click="caracteristicasIsp(plan_isp.id)"
          >
            Ver todas las caracteristicas
          </button>
        </div>
      </div>
      <div class="card-footer bg-transparent border-top text-muted no-borders" style="background-color: #fff;">
        <router-link :to="{ name: 'sistemaPlanesIsp' }">
          <div class="p-2 m-0 rounded text-center bg-soft-dark">
            <span class="h4" style="color: #337AB7;">
              <strong>Planes ISP disponibles</strong>
            </span>
          </div>
        </router-link>
      </div>
    </div>
    <mdl-caracteristicas-isp ref="mdlCaracteristicasIsp"></mdl-caracteristicas-isp>
  </div>
</template>

<script>
import MdlCaracteristicasIsp from "./isp/MdlCaracteristicasIsp.vue"
import CuentaSrv from "@/services/CuentaSrv.js"
import accounting from 'accounting/accounting.js'
export default {
  name: 'WModuloIsp',
  props: [ 'modulo_isp', 'modulos_activos' ],
  components: {
    MdlCaracteristicasIsp
  },
  data() {
    return {
      estado_modulo: false,

      plan_isp: {
        id:null,
        nombre: "",
        descripcion: "",
        max_clientes: 0,
        max_contratos: 0,
        max_cpes: 0,
        max_routers: 0,
        notificacion_routers_caidos: false,
        generacion_facturas: true,
        cortes_servicios: true,
        mensaje_servicio_suspendido: false,
        reportes_diarios: true,
        notificacion_por_email: false,
        precio_mensual: 0,
        precio_anual: 0,
      }
    }
  },
  watch: {
    'modulo_isp.id_plan':function(newVal, oldVal) {
      var self = this
      if(self.modulo_isp.id_plan == null) return

      self.cargarPlan()
    }
  },
  created: function() {
    var self = this

    self.cargarEstado()
  },
  methods: {
    actDescEstModulo: function() {
      var self = this

      if(self.estado_modulo != 1) {
        CuentaSrv.activarModulo('isp').then(response => {
          iu.msg.success('Modulo ISP activado correctamente')
          self.cargarEstado()
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo activar el modulo isp'
          }
          iu.msg.error(mensaje)
          console.log(error)
        }).finally(() => {
          iu.bus.emit('actualizarCuenta')
        })
      } else {
        var index = this.modulos_activos.indexOf('hotspot')

        if( index == -1 ) {
          iu.msg.warning("No se puede desactivar el módulo ISP, ya que se requiere por lo menos el módulo ISP u Hotspot activado.")
          return
        }

        CuentaSrv.desactivarModulo('isp').then(response => {
          iu.msg.success('Modulo ISP desactivado correctamente')
          self.cargarEstado()
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo desactivar el modulo isp'
          }
          iu.msg.error(mensaje)
          console.log(error)
        }).finally(() => {
          iu.bus.emit('actualizarCuenta')
        })
      }
    },

    caracteristicasIsp: function(idPlan) {
      this.$refs.mdlCaracteristicasIsp.mostrar(idPlan, "tablero")
    },

    cargarEstado: function() {
      var self = this

      CuentaSrv.estadoModulo('isp').then(response => {
        self.estado_modulo = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar cuenta'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarPlan: function (){
      var self = this,
          idPlan = self.modulo_isp.id_plan

      CuentaSrv.moduloPlanJSON('isp', idPlan).then(response => {
        self.plan_isp = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar cuenta'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    formatoMoneda: function (numero) {
      return accounting.formatMoney(numero)
    },
  }
}
</script>

<style scoped>

</style>