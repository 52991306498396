<template>
  <div>
    <div class="card plan-box overflow-hidden">
      <div class="bg-success p-3">
        <div class="d-flex">
          <div class="flex-grow-1">
            <h5 class="text-white">Tipo de suscripción</h5>
          </div>
          <div class="flex-shrink-0 ms-3">
            <i class="bx bx-revision h3 mb-0 text-white"></i>
          </div>
        </div>
      </div>
      <div class="card-body p-4">
        <div class="py-2 text-left">
          <h5 class="mb-0">
            Elige un plan de suscripción
            <br>
            <span class="font-size-14 text-muted">
              Encuentra la suscripción que se adecua a tus necesidades.
            </span>
          </h5>
        </div>
        <div class="plan-features mt-4">
          <div class="form-check mb-3" style="display: inline-block;">
            <input
              class="form-check-input form-check-input-success"
              type="radio" name="tipo_suscripcion" id="mensual"
              v-model="tipo_suscripcion" :value="1" @change="actualizarTipoSuscripcion()"
            />
            <label class="form-check-label" :class="{ 'text-success': tipo_suscripcion == 1 }" for="mensual">
              <strong>Suscripción mensual</strong>
            </label>
          </div>
          &nbsp;
          <div class="form-check mb-3" style="display: inline-block;">
            <input
              class="form-check-input form-check-input-success"
              type="radio" name="tipo_suscripcion" id="anual"
              v-model="tipo_suscripcion" :value="2" @change="actualizarTipoSuscripcion()"
            />
            <label class="form-check-label" :class="{ 'text-success': tipo_suscripcion == 2 }" for="anual">
              <strong>Suscripción anual</strong>
            </label>
          </div>
          <p v-show="tipo_suscripcion != ultima_suscripcion.tipo_suscripcion">
            El cambio se verá reflejado a partir del fecha: <strong>{{formatoFecha(ultima_suscripcion.fin)}}</strong>
          </p>

          <div class="text-right">
            <button class="btn btn-success" @click="actualizarTipoSuscripcion()">
              <i class="mdi mdi-content-save"></i>
              Actualizar tipo de suscripción
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CuentaSrv from "@/services/CuentaSrv.js"
import moment from 'moment'
export default {
  name: 'TipoSuscripcion',
  props: [ 'empresa_livaur' ],
  data() {
    return {
      tipo_suscripcion: 1,
      ultima_suscripcion: {
        almacenamiento_usado:0,
        cantidad_clientes:0,
        cantidad_contratos:0,
        cantidad_cpes:0,
        cantidad_credenciales:0,
        cantidad_fichas:0,
        cantidad_hotspots: 0,
        cantidad_productos:0,
        cantidad_routers:0,
        estado:0,
        fin:"",
        id:null,
        id_cliente:null,
        id_empresa:null,
        id_plan_hotspot:null,
        id_plan_inventario:null,
        id_plan_isp:null,
        id_plan_vpn:null,
        inicio:"",
        nota:"",
        nota_interna:null,
        notificacion_por_vencer_enviada:0,
        precio_plan_hotspot:0,
        precio_plan_inventario:0,
        precio_plan_isp:0,
        precio_plan_vpn:0,
        renovar:0,
        tipo_suscripcion:0,
        total:0,
        total_plan_hotspot:0,
        total_plan_inventario:0,
        total_plan_isp:0,
        total_plan_vpn:0
      }
    }
  },
  watch: {
    'empresa_livaur.tipo_suscripcion':function(newVal, oldVal) {
      var self = this
      self.tipo_suscripcion = self.empresa_livaur.tipo_suscripcion
    }
  },
  created: function() {
    var self = this

    self.suscripcion()
  },
  methods: {
    actualizarTipoSuscripcion(){
      var self = this,
        data = {
          tipo_suscripcion: self.tipo_suscripcion
        }

      CuentaSrv.actualizarTipoSuscripcion(data).then(response => {
        iu.msg.success('Se actualizó correctamente el tipo de suscripción')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar cuenta'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        iu.bus.emit('refescarSaldo')
      })
    },

    formatoFecha(mt, formato = 'DD/MM/YYYY') {
      return moment(mt).locale('es-mx').format(formato)
    },

    suscripcion() {
      var self = this

      CuentaSrv.suscripcionJSON().then(response => {
        self.ultima_suscripcion = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar cuenta'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>
.form-check-input-success:checked {
  background-color: #63AD6F;
  color: #63AD6F;
  border-color: #63AD6F;
}
</style>