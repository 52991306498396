<template>
  <div>
    <div class="card plan-box overflow-hidden">
      <div class="bg-success p-3">
        <div class="d-flex">
          <div class="flex-grow-1">
            <h5 class="text-white">Módulo Hotspot</h5>
          </div>
          <div class="flex-shrink-0 ms-3">
            <i class="bx bx-wifi h3 mb-0 text-white"></i>
          </div>
        </div>
      </div>
      <div class="card-body p-4">
        <div v-show="estado_modulo">
          <h2 class="text-center">
            <strong>Plan {{modulo_hotspot.nombre_plan}}</strong>
          </h2>
          <br>
          <div class="table-responsive">
            <table class="table">
              <tbody class="text-center">
                <tr style="width:50%;">
                  <td>
                    Fichas
                  </td>
                  <td>
                    {{modulo_hotspot.cantidad_fichas}} de {{plan_hotspot.max_fichas != -1 ? plan_hotspot.max_fichas : 'Ilimitado'}}
                  </td>
                </tr>
                <tr>
                  <td>
                    Hotspots
                  </td>
                  <td>
                    {{modulo_hotspot.cantidad_hotspots}} de {{plan_hotspot.max_hotspots != -1 ? plan_hotspot.max_hotspots : 'Ilimitado'}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br><br><br><br><br>
          <h3 class="text-center">
            <strong>Precio {{formatoMoneda(plan_hotspot.precio_mensual)}} USD</strong>
          </h3>
        </div>
        <div v-show="!estado_modulo" class="text-center p-5 m-2">
          <span>
            <i class="bx bxs-info-circle text-warning" style="font-size: 10em;"></i>
          </span>
          <br><br>
          <span class="h3">
            <strong>Módulo desactivado</strong>
          </span>
        </div>

        <div class="text-center">
          <button class="btn bg-gradient btn-lg" 
            :class="{
              'btn-success': estado_modulo,
              'btn-danger': !estado_modulo
            }"
            @click="actDescEstModulo()"
          >
            <strong>
              MODULO {{ estado_modulo ? 'ACTIVO' : 'INACTIVO' }}
            </strong>
          </button>
        </div>
        <div class="text-center" v-show="estado_modulo">
          <button
            class="btn btn-link text-primary"
            @click="caracteristicasHotspot(plan_hotspot.id)"
          >
            Ver todas las caracteristicas
          </button>
        </div>
      </div>
      <div class="card-footer bg-transparent border-top text-muted no-borders" style="background-color: #fff;">
        <router-link :to="{ name: 'sistemaModuloHotspot' }">
          <div class="p-2 m-0 rounded text-center bg-soft-dark">
            <span class="h4" style="color: #337AB7;">
              <strong>Planes Hotspot disponibles</strong>
            </span>
          </div>
        </router-link>
      </div>
    </div>
    <MdlCaracteristicasHotspot ref="mdlCaracteristicasHotspot"></MdlCaracteristicasHotspot>
  </div>
</template>

<script>
import MdlCaracteristicasHotspot from "./hotspot/MdlCaracteristicasHotspot.vue"
import CuentaSrv from "@/services/CuentaSrv.js"
import accounting from 'accounting/accounting.js'
export default {
  name: 'WModuloHotspot',
  props: [ 'modulo_hotspot', 'modulos_activos' ],
  components: {
    MdlCaracteristicasHotspot
  },
  data() {
    return {
      estado_modulo: false,

      plan_hotspot: {
        id: null,
        descripcion: "",
        layout_fichas: 0,
        layout_portal_cautivo: 0, 
        max_fichas: 0,
        max_hotspots: 0,
        max_routers: 0,
        nombre: "", 
        notificacion_routers_caidos: 0, 
        precio_anual: 0,
        precio_mensual: 0
      }
    }
  },
  watch: {
    'modulo_hotspot.id_plan':function(newVal, oldVal) {
      var self = this
      if(self.modulo_hotspot.id_plan == null) return

      self.cargarPlan()
    }
  },
  created: function() {
    var self = this

    self.cargarEstado()
  },
  methods: {
    actDescEstModulo: function() {
      var self = this

      if(self.estado_modulo != 1) {
        CuentaSrv.activarModulo('hotspot').then(response => {
          iu.msg.success('Modulo Hotspot activado correctamente')
          self.cargarEstado()
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo activar el modulo hotspot'
          }
          iu.msg.error(mensaje)
          console.log(error)
        }).finally(() => {
          iu.bus.emit('actualizarCuenta')
        })
      } else {
        var index = this.modulos_activos.indexOf('isp')

        if( index == -1 ) {
          iu.msg.warning("No se puede desactivar el módulo Hotspot, ya que se requiere por lo menos el módulo ISP u Hotspot activado.")
          return
        }

        CuentaSrv.desactivarModulo('hotspot').then(response => {
          iu.msg.success('Modulo Hotspot desactivado correctamente')
          self.cargarEstado()
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo desactivar el modulo hotspot'
          }
          iu.msg.error(mensaje)
          console.log(error)
        }).finally(() => {
          iu.bus.emit('actualizarCuenta')
        })
      }
    },

    caracteristicasHotspot: function(idHotspot) {
      this.$refs.mdlCaracteristicasHotspot.mostrar(idHotspot, "tablero")
    },

    cargarEstado: function() {
      var self = this

      CuentaSrv.estadoModulo('hotspot').then(response => {
        self.estado_modulo = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar cuenta'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarPlan: function (){
      var self = this,
          idPlan = self.modulo_hotspot.id_plan

      CuentaSrv.moduloPlanJSON('hotspot', idPlan).then(response => {
        self.plan_hotspot = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar cuenta'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    formatoMoneda: function (numero) {
      return accounting.formatMoney(numero)
    },
  }
}
</script>

<style scoped>

</style>